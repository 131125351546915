import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h3: "h3",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Internationalization is a critical component at Blitz that enables the translation of text into 15+ languages. If you haven't worked with i18n before, we recommend you start with the Introduction at ", _jsx(_components.a, {
        href: "https://www.i18next.com/",
        children: "i18next.com"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "The two most important things you should apply when working on Blitz:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["All strings ", _jsx(_components.strong, {
          children: "and"
        }), " numbers rendered in Blitz need to be localized, typically through ", _jsx(_components.a, {
          href: "https://react.i18next.com/",
          children: "react-i18next"
        }), " using the ", _jsx(_components.code, {
          children: "t()"
        }), " function from ", _jsx(_components.code, {
          children: "useTranslation()"
        }), " or the ", _jsx(_components.code, {
          children: "<Trans />"
        }), " component"]
      }), "\n", _jsxs(_components.li, {
        children: ["Never concatentate rendered strings or numbers in a component. Instead you should use ", _jsx(_components.a, {
          href: "https://www.i18next.com/translation-function/interpolation",
          children: "Interpolation"
        }), " to work with dynamic values in translations."]
      }), "\n", _jsx(_components.li, {
        children: "You may encounter some bad practices ported over from Blitz Web that we are still cleaning up. If you are unsure about a practice, reach out for some guidance."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "usage-with-react",
      children: _jsx(_components.a, {
        href: "#usage-with-react",
        children: "Usage with React"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Translations are fairly straight forward once you become familiar with the framework. Typically you will leverage the hook ", _jsx(_components.code, {
        children: "const { t } = useTranslation()"
      }), " and provide: a key for translation lookup, fallback translation text if the key doesn't exist, and any values for interpolation:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const { t } = useTranslation();\n\n// renders: Welcome Clutch to Blitz!\nt(\"common:welcomeMessage\", \"Welcome {{ username }} to Blitz!\", {\n  username: \"Clutch\",\n});\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "formatting-numbers",
      children: _jsx(_components.a, {
        href: "#formatting-numbers",
        children: "Formatting Numbers"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["You can specify a formatter such as ", _jsx(_components.code, {
        children: "number"
      }), " or ", _jsx(_components.code, {
        children: "percent"
      }), " after a parameter:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "// renders: 2.1 KDA\nt(\"common:stats.kda\", \"{{ kda, number }} KDA\", {\n  kda: 2.156,\n});\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["When working with percentages, always prefer to keep values in a range of ", _jsx(_components.code, {
        children: "[0,1]"
      }), " and let the formatter do the work."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "// renders: 15% HS\nt(\"common:stats.hs\", \"{{ hs, percent }} HS\", {\n  hs: 0.156,\n});\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you need to format the level of precision, you can pass in formatting options that are accepted by the standard ", _jsx(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl",
        children: "Intl API"
      }), "."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "// renders: 2.156 KDA\nt(\"common:stats.kda\", \"{{ kda, number }} KDA\", {\n  kda: 2.156,\n  formatParams: {\n    maximumFractionDigits: 3,\n  },\n});\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you need to format a number by itself, you don't have to use the ", _jsx(_components.code, {
        children: "t()"
      }), " function. Number formatting should use the ", _jsx(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl",
        children: "Intl API"
      }), " for formatting, and we have a few helpers available in ", _jsx(_components.code, {
        children: "i18n-helpers.mjs"
      }), " such as ", _jsx(_components.code, {
        children: "formatToPercent(language, value, { min, max })"
      }), " and ", _jsx(_components.code, {
        children: "formatToFixedNumber(language, value, maximumFractionDigits)"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const {\n  i18n: { language },\n} = useTranslation();\n\n// renders: 56.5%\nreturn <span>{formatToPercent(language, 0.5653, { max: 1 })}</span>;\n\n// renders: 10.4\nreturn <span>{formatToFixedNumber(language, 10.453, 1)}</span>;\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "common-mistakes",
      children: _jsx(_components.a, {
        href: "#common-mistakes",
        children: "Common Mistakes"
      })
    }), "\n", _jsx(_components.p, {
      children: "Review these best practices for avoiding common mistakes that happen when a developer is new to localization."
    }), "\n", _jsx(_components.h3, {
      id: "always-interpolate-values-in-i18n-fallbacks",
      children: _jsx(_components.a, {
        href: "#always-interpolate-values-in-i18n-fallbacks",
        children: "Always interpolate values in i18n fallbacks"
      })
    }), "\n", _jsx(_components.p, {
      children: "Never render variables directly into your strings. Instead always use interpolation and provide the values in the third argument of the translation function."
    }), "\n", _jsx(_components.p, {
      children: "Good example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "t(\"common:greeting\", \"Hi {{name}}!\", { name });\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "NEVER DO THIS:"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "t(\"common:greeting\", `Hi ${name}!`);\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "always-use-i18n-for-anything-that-is-displayed-to-the-user",
      children: _jsx(_components.a, {
        href: "#always-use-i18n-for-anything-that-is-displayed-to-the-user",
        children: "Always use i18n for anything that is displayed to the user"
      })
    }), "\n", _jsx(_components.p, {
      children: "If you are rendering any text to the user (strings or numbers) you should be passing it through localization using i18n or the Intl API."
    }), "\n", _jsx(_components.p, {
      children: "Good example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "function MyComponent() {\n  const {\n    t,\n    i18n: { language },\n  } = useTranslation();\n  const text = t(\"namespace:label\", \"Some Label\");\n\n  return (\n    <>\n      <p>{text}</p>\n      <p>{formatToPercent(language, 0.534)}</p>\n    </>\n  );\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "NEVER DO THIS:"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "function MyComponent() {\n  const text = \"Noob dev do this\";\n\n  // No localization!\n  return <p>{text}</p>;\n}\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "assume-the-language-may-change-at-any-time",
      children: _jsx(_components.a, {
        href: "#assume-the-language-may-change-at-any-time",
        children: "Assume the language may change at any time"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Assume the language might change for your component at any time. The ", _jsx(_components.code, {
        children: "useTranslation"
      }), " hook will handle this for you. You can also access the current language and pass it to one of the formatting helpers or the Int API."]
    }), "\n", _jsx(_components.p, {
      children: "Good example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const {\n  i18n: { language },\n} = useTranslation();\n\nreturn <span>{formatToPercent(language, 0.5653, { max: 0 })}</span>;\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "NEVER DO THIS:"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import i18n from \"i18next\";\n\nreturn <span>{formatToPercent(i18n.language, 0.5653, { max: 0 })}</span>;\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "other-helpful-information",
      children: _jsx(_components.a, {
        href: "#other-helpful-information",
        children: "Other Helpful Information"
      })
    }), "\n", _jsx(_components.p, {
      children: "A few things you should be thinking about when working with i18n and React:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["We have several namespaces for our translation files. For example, we have a ", _jsx(_components.code, {
          children: "common"
        }), " namespace for translations used app-wide, and several that are game-specific such as ", _jsx(_components.code, {
          children: "lol"
        }), ", ", _jsx(_components.code, {
          children: "apex"
        }), ", etc."]
      }), "\n", _jsx(_components.li, {
        children: "Keep a list of new translation keys that you add and share them with the team so we can make sure to get translations as needed for these new keys."
      }), "\n", _jsxs(_components.li, {
        children: ["Assume the language can change at any time - you should leverage ", _jsx(_components.code, {
          children: "useTranslation()"
        }), " so your component re-renders appropriately when the language changes."]
      }), "\n", _jsxs(_components.li, {
        children: ["Use the ", _jsx(_components.a, {
          href: "https://react.i18next.com/latest/trans-component",
          children: "Trans component"
        }), " if you need to render HTML in your translation such as ", _jsx(_components.code, {
          children: "<strong>"
        }), " or ", _jsx(_components.code, {
          children: "<span>"
        }), " tags."]
      }), "\n", _jsxs(_components.li, {
        children: ["We override the default formatters at ", _jsx(_components.code, {
          children: "src/i18n/i18n.mjs"
        }), " - if you see unexpected formatting issues, you might confirm if the formatter needs to be updated."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.a, {
          href: "https://www.i18next.com/translation-function/formatting#list",
          children: "Lists"
        }), " and ", _jsx(_components.a, {
          href: "https://www.i18next.com/translation-function/plurals",
          children: "pluralization"
        }), " are also important to consider with i18n. Review these concepts if you encounter these in your feature."]
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
